import { useContext } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Link as RouterLink } from 'react-router-dom';
import * as yup from 'yup';
import { useIntl } from 'react-intl';
import { authContext } from 'helpers/services';
import { Spinner } from 'components';


let Login = () => {
    let { formatMessage } = useIntl();

    let authService = useContext(authContext);

    let loginSchema = yup.object().shape({
        clientId: yup.string().required().label("Email").default(''),
        clientSecret: yup.string().required().label("Password").default('')
    })


    return (
        <Formik
            enableReinitialize={true}
            initialValues={loginSchema.getDefault()}
            validationSchema={loginSchema}
            onSubmit={async (values, action) => {
                action.setSubmitting(true);
                await authService.signInWithCredentials(values.clientId, values.clientSecret);
                action.setSubmitting(false);
            }}
        >
            {({ errors }) =>
                <Form className="needs-validation">
                    <div className="accountpanel">
                        <div className="form-container p-0 pt-5 pt-lg-0">
                            <div className="container order-2 order-md-1 bg-white py-4 px-4 px-md-5">
                                <span className="brand-logo"></span>
                                <div className="row">
                                    <div className="col-12 pb-4">{formatMessage({ id: "login_with_socials" })}</div>
                                    <div className="col">
                                        <button className="accountButton account-fb" onClick={() => authService.federatedSignIn('Facebook')}>Facebook</button>
                                    </div>
                                    <div className="col">
                                        <button className="accountButton account-google" onClick={() => authService.federatedSignIn('Google')}>Google</button>
                                    </div>
                                </div>

                                <div className="row pt-5">
                                    <div className="col-12 pb-4">{formatMessage({ id: "login_with_credentials" })}</div>
                                    <div className="col">
                                        <div className="form-group">
                                            <label htmlFor="clientId">{formatMessage({ id: "email" })}</label>
                                            <Field
                                                disabled={authService.isLoading}
                                                id="clientId"
                                                type="email"
                                                className={`form-control ${errors.clientId ? 'is-invalid' : ''}`}
                                                name="clientId"
                                            />
                                            <ErrorMessage name="clientId" component="span">
                                                {message => <span className="invalid-feedback">{formatMessage({ id: 'field_required' }, { field: formatMessage({ id: 'email' }) })}</span>}
                                            </ErrorMessage>
                                        </div>
                                        <div className="form-group pt-2">
                                            <label htmlFor="textinput">{formatMessage({ id: "password" })}</label>
                                            <Field
                                                disabled={authService.isLoading}
                                                className={`form-control ${errors.clientId ? 'is-invalid' : ''}`}
                                                name="clientSecret"
                                                type="password"
                                            />
                                            <ErrorMessage name="clientSecret" component="span">
                                                {message => <span className="invalid-feedback">{formatMessage({ id: 'field_required' }, { field: formatMessage({ id: 'password' }) })}</span>}
                                            </ErrorMessage>
                                        </div>
                                    </div>
                                </div>
                                <div className="row pt-3">
                                    <div className="col-12 col-md-6">
                                        <RouterLink to="/account/forgotpassword" className="btn btn-link btn-sm">{formatMessage({ id: "forgot_password" })}</RouterLink>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        {authService.isLoading && <Spinner />}
                                        {authService.isLoading === false &&
                                            <button style={{ float: 'right' }} className="btn btn-block btn-success w-100" type="submit">
                                                {formatMessage({ id: 'login' })}
                                            </button>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="container order-1 order-md-2 mb-4 mb-md-0 p-4 p-md-5 bg-success text-white">
                                <div className="row align-items-center h-100">
                                    <div className="col">
                                        <h3>{formatMessage({ id: "not_registered" })}</h3>
                                        <p>{formatMessage({ id: "not_registered_subtitle" })}</p>
                                        <RouterLink to="/account/signup">
                                            <button className="btn btn-lg btn-light bg-white px-5">
                                                {formatMessage({ id: "register" })}
                                            </button>
                                        </RouterLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Form>
            }
        </Formik>
    );
}

export default Login;