import { useContext, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { authContext } from 'helpers/services';
import { Spinner } from 'components';


let SignUpConfirm = () => {
    let history = useHistory();
    let location = useLocation();
    let { formatMessage } = useIntl();

    let authService = useContext(authContext);

    let signUpConfirmSchema = yup.object().shape({
        code: yup.string().required().label(formatMessage({ id: 'code' })).default(''),
        email: yup.string().required().label(formatMessage({ id: 'email' })).default(''),
    })

    let [isLoading, setLoading] = useState(false);


    let resendConfirm = async (email: string) => {
        if (email === '') {
            toast.error(formatMessage({ id: 'err_must_set_email' }));
            return;
        }

        try {
            await authService.resendConfirm(email);
            toast.error(formatMessage({ id: 'info_verification_code_sent' }));
        } catch (err) {
            toast.error(formatMessage({ id: 'err_unable_to_send_verification_code' }));
        }
    }


    return (
        <Formik
            initialValues={{ ...signUpConfirmSchema.getDefault(), email: (location.state as any)?.email || '' }}
            validationSchema={signUpConfirmSchema}
            onSubmit={async (values, action) => {
                try {
                    setLoading(true);
                    await authService.confirmSignUp(values.email, values.code);
                    history.push('/account/signupconfirmed');
                } catch (err) {
                    setLoading(false);
                    action.setSubmitting(false);
                }
            }}
        >
            {({ errors, values }) =>
                <Form className="needs-validation">
                    <div className="accountpanel">
                        <div className="form-container p-0 pt-5 pt-lg-0">
                            <div className="container bg-white py-4 px-4 px-md-5">
                                <span className="brand-logo"></span>

                                <div className="row">
                                    <div className="col-12">
                                        <h5 className="pb-4">{formatMessage({ id: "signup_confirm" })}</h5>

                                        <div className="form-group pb-3">
                                            <label htmlFor="textinput"><FormattedMessage id="email" />&nbsp;*</label>
                                            <Field
                                                type="email"
                                                className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                                                name="email"
                                            />
                                            <ErrorMessage name="email" component="span">
                                                {message => <span className="invalid-feedback">{formatMessage({ id: 'field_required' }, { field: formatMessage({ id: 'email' }) })}</span>}
                                            </ErrorMessage>
                                        </div>
                                        <div className="form-group pb-3">
                                            <label htmlFor="textinput"><FormattedMessage id="code" />&nbsp;*</label>
                                            <Field
                                                type="text"
                                                className={`form-control ${errors.code ? 'is-invalid' : ''}`}
                                                name="code"
                                            />
                                            <ErrorMessage name="code" component="span">
                                                {message => <span className="invalid-feedback">{formatMessage({ id: 'field_required' }, { field: formatMessage({ id: 'code' }) })}</span>}
                                            </ErrorMessage>
                                        </div>

                                        {isLoading && <Spinner />}
                                        {isLoading === false &&
                                            <button className="btn btn-success btn-block w-100 mt-4" type="submit">
                                                {formatMessage({ id: "confirm" })}
                                            </button>
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="container mt-4 p-5 bg-dark text-white">
                                <div className="row">
                                    <div className="col-12">
                                        <h3>{formatMessage({ id: "verification_code_not_arrived" })}</h3>
                                        <p>{formatMessage({ id: "click_on_button" })}</p>
                                        <button type="button" className="btn btn-light btn-lg bg-white" onClick={() => resendConfirm(values.email)}>
                                            {formatMessage({ id: "send" })}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Form>
            }
        </Formik>
    );
}

export default SignUpConfirm;