import { useContext } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { IntlProvider } from 'react-intl';

import { Layout } from 'components';

import PrivateRoute from './PrivateRoute';
import { Localization } from 'helpers/settings';
import { applicationContext } from 'helpers/services';

import { User, Error404, CookiesPolicy } from 'views';
import { Login, SignUp, SignUpConfirm, SignUpConfirmed, ForgotPassword, ResetPassword } from 'views/Account';


let Routes = () => {
    let appService = useContext(applicationContext);

    return (
        <IntlProvider locale="en" messages={Localization[appService.currentCountry]}>
            <Layout>
                <Switch>
                    <PrivateRoute exact path="/user" component={User} />

                    <Route exact path="/account/login" component={Login} />
                    <Route exact path="/account/signup" component={SignUp} />
                    <Route exact path="/account/signupconfirm" component={SignUpConfirm} />
                    <Route exact path="/account/signupconfirmed" component={SignUpConfirmed} />
                    <Route exact path="/account/forgotpassword" component={ForgotPassword} />
                    <Route exact path="/account/resetpassword" component={ResetPassword} />

                    <Route exact path="/site-terms/cookies-policy" component={CookiesPolicy} />

                    <Route exact path="/error404" component={Error404} />

                    <Redirect to="/user" />
                </Switch>
            </Layout>
        </IntlProvider>
    )
}

export default Routes;