import { useContext, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useOutsideClick } from 'helpers';
import { useHistory } from 'react-router';
import { authContext } from 'helpers/services';


let UserMenu = () => {
    let history = useHistory();
    let { formatMessage } = useIntl();

    let authService = useContext(authContext);

    let [showUserMenu, setShowUserMenu] = useState(false);

    let myRef = useRef<HTMLInputElement>(null);

    useOutsideClick(myRef, () => {
        if (showUserMenu === true)
            setShowUserMenu(false);
    });

    let handleLogout = () => {
        setShowUserMenu(false);
        authService.signOut();
    }

    let handleLogin = () => {
        setShowUserMenu(false);
        history.push('/account/login');
    }


    return (
        <div className="actions-menu">
            {authService.isLoading === false &&
                <>
                    {authService.isAuthenticated === false &&
                        <button
                            type="button"
                            className="btn btn-block btn-outlined-primary"
                            onClick={handleLogin}
                            title={formatMessage({ id: "login" })}
                        >
                            <img src="/images/user-alt.svg" alt="" width="15px" />
                        </button>
                    }
                    {authService.isAuthenticated === true &&
                        <div className="dropdown">
                            <button
                                className={`btn btn-link dropdown-toggle${showUserMenu === true ? 'show' : ''}`}
                                id="dropdownMenu"
                                data-bs-toggle="dropdown"
                                aria-expanded={showUserMenu === true ? 'true' : 'false'}
                                onClick={() => setShowUserMenu(!showUserMenu)}
                            >
                                <img src="/images/user-alt.svg" alt="" width="15px" />
                            </button>
                            <div
                                ref={myRef}
                                className={`dropdown-menu${showUserMenu === true ? ' show' : ''} dropdown-menu-left`} aria-labelledby="dropdownMenu"
                            >
                                <li>
                                    <button
                                        type="button"
                                        className="dropdown-item"
                                        onClick={handleLogout}
                                    >
                                        <FormattedMessage id="logout" />
                                    </button>
                                </li>
                            </div>
                        </div>
                    }
                </>
            }
        </div>
    );
}

export default UserMenu;