import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'helpers/Extensions';

import 'react-toastify/dist/ReactToastify.min.css';
import "react-datepicker/dist/react-datepicker.css";

import "css/bootstrap.scss";
import "css/header.scss";
import "css/footer.scss";

import "css/sites_cookiebot.css";

import "css/style.scss";

import { Amplify } from 'aws-amplify';
import { AuthSettings } from 'helpers/settings';

import { Routes } from 'components';
import { AuthenticationProvider, ApplicationProvider, AxiosInterceptors } from 'helpers/services';


let hostname = window.location.hostname.toUpperCase();
let redirectSignIn: string | undefined = '';
let redirectSignOut: string | undefined = '';
let urlCallback: string;
let footerClass: string;
let footerLogo: string;

if (hostname.includes("MANFROTTO")) {
  document.body.className = "brand-MNF";
  footerClass = "logo-man-lg-w";
  footerLogo = "https://cdn.videndummediasolutions.com/typo3conf/ext/theme/Resources/Public/images/Manfrotto_Endorsement_Logo_White.svg"
  urlCallback = 'https://www.manfrotto.com';
  redirectSignIn = AuthSettings.CONFIG.Auth.oauth.redirectSignIn?.replace('{domain}', 'manfrotto');
  redirectSignOut = AuthSettings.CONFIG.Auth.oauth.redirectSignOut?.replace('{domain}', 'manfrotto');
}
else if (hostname.includes("JOBY")) {
  document.body.className = "brand-JBY";
  footerClass = "logo-bjb-lg-w";
  footerLogo = "https://cdn.videndummediasolutions.com/typo3conf/ext/theme/Resources/Public/images/Joby_Endorsement_Logo_White.svg";
  urlCallback = 'https://www.joby.com';
  redirectSignIn = AuthSettings.CONFIG.Auth.oauth.redirectSignIn?.replace('{domain}', 'joby');
  redirectSignOut = AuthSettings.CONFIG.Auth.oauth.redirectSignOut?.replace('{domain}', 'joby');
}
else if (hostname.includes("GITZO")) {
  document.body.className = "brand-GTZ";
  footerClass = "logo-bgi-lg-w";
  footerLogo = "https://cdn.videndummediasolutions.com/typo3conf/ext/theme/Resources/Public/images/Gitzo_Endorsement_Logo_White.svg";
  urlCallback = 'https://www.gitzo.com';
  redirectSignIn = AuthSettings.CONFIG.Auth.oauth.redirectSignIn?.replace('{domain}', 'gitzo');
  redirectSignOut = AuthSettings.CONFIG.Auth.oauth.redirectSignOut?.replace('{domain}', 'gitzo');
}
else if (hostname.includes("LOWEPRO")) {
  document.body.className = "brand-LWP";
  footerClass = "logo-blw-lg-w";
  footerLogo = "https://cdn.videndummediasolutions.com/typo3conf/ext/theme/Resources/Public/images/Lowepro_Endorsement_Logo_White.svg";
  urlCallback = 'https://www.lowepro.com';
  redirectSignIn = AuthSettings.CONFIG.Auth.oauth.redirectSignIn?.replace('{domain}', 'lowepro');
  redirectSignOut = AuthSettings.CONFIG.Auth.oauth.redirectSignOut?.replace('{domain}', 'lowepro');
}
else if (hostname.includes("SYRP")) {
  document.body.className = "brand-SPY";
  footerClass = "logo-bsy-lg-w";
  footerLogo = "https://cdn.videndummediasolutions.com/typo3conf/ext/theme/Resources/Public/images/Syrp_Endorsement_Logo_White.svg";
  urlCallback = 'https://www.syrplab.com';
  redirectSignIn = AuthSettings.CONFIG.Auth.oauth.redirectSignIn?.replace('{domain}', 'syrplab');
  redirectSignOut = AuthSettings.CONFIG.Auth.oauth.redirectSignOut?.replace('{domain}', 'syrplab');
}
else if (hostname.includes("SAVAGEUNIVERSAL")) {
  document.body.className = "brand-SAV";
  footerClass = "logo-sav-lg-w";
  footerLogo = "https://static.videndummediasolutions.com/media/external/Savage_Endorsement_Logo_White.svg";
  urlCallback = 'https://savageuniversal.com';
  redirectSignIn = AuthSettings.CONFIG.Auth.oauth.redirectSignIn?.replace('{domain}', 'savageuniversal');
  redirectSignOut = AuthSettings.CONFIG.Auth.oauth.redirectSignOut?.replace('{domain}', 'savageuniversal');
}
else if (hostname.includes("RYCOTE")) {
  document.body.className = "brand-RYC";
  footerClass = "logo-bry-lg-w";
  footerLogo = "https://cdn.videndummediasolutions.com/typo3conf/ext/theme/Resources/Public/images/Lowepro_Endorsement_Logo_White.svg";
  urlCallback = 'https://rycote.com';
  redirectSignIn = AuthSettings.CONFIG.Auth.oauth.redirectSignIn?.replace('{domain}', 'rycote');
  redirectSignOut = AuthSettings.CONFIG.Auth.oauth.redirectSignOut?.replace('{domain}', 'rycote');
}
else {
  urlCallback = 'https://www.videndummediasolutions.com';
  footerLogo = "https://cdn.videndummediasolutions.com/typo3conf/ext/theme/Resources/Public/images/VIS_Endorsement_Logo_White.svg";
  redirectSignIn = AuthSettings.CONFIG.Auth.oauth.redirectSignIn?.replace('{domain}', 'videndummediasolutions');
  redirectSignOut = AuthSettings.CONFIG.Auth.oauth.redirectSignOut?.replace('{domain}', 'videndummediasolutions');
}

Amplify.configure({
  ...AuthSettings.CONFIG,
  Auth: {
    ...AuthSettings.CONFIG.Auth,
    oauth: {
      ...AuthSettings.CONFIG.Auth.oauth,
      redirectSignIn,
      redirectSignOut
    }
  }
});

let App = () => {
  return (
    <BrowserRouter>
      <ToastContainer
        autoClose={4000}
        draggable={false}
        limit={20}
        closeOnClick
        hideProgressBar
        position="bottom-left"
        closeButton={false}
      />
      <ApplicationProvider
        urlCallback={urlCallback}
        footerClass={footerClass}
        footerLogo={footerLogo}
      >
        <AuthenticationProvider>
          <AxiosInterceptors />
          <Routes />
        </AuthenticationProvider>
      </ApplicationProvider>
    </BrowserRouter>
  );
}

export default App;