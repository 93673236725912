import { useIntl, FormattedMessage } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';


let SignUpConfirmed = () => {
    let { formatMessage } = useIntl();


    return (
        <div className="accountpanel">
        <div className="form-container p-0 pt-5 pt-lg-0">
            <div className="container bg-white py-4 px-4 px-md-5 text-center">
                <span className="brand-logo"></span>
                <div className="row">
                    <div className="col-md-8 col-12 mx-auto">
                        <h3>{formatMessage({ id: "signup_confirmed" })}</h3>

                        <div className="pt-4 form-group">
                            <RouterLink to="/account/login">
                                <button className="btn btn-success btn-lg px-5 btn-block">
                                    <FormattedMessage id="login" />
                                </button>
                            </RouterLink>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
    );
}

export default SignUpConfirmed;