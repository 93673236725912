import { useState } from "react";
import { FormattedMessage } from 'react-intl';

let Brands = () => {

    return (
        <div className="videndum-section">
            <div className="collapsible-section">
                <div className="container">
                    <div className='videndum-list open' style={{ display: "none" }}>
                        <a className="close-videndum-menu"></a>
                        <div className="cols-container">
                            <div className="cols col-large-2">
                                <h2><FormattedMessage id="capture" /><span><FormattedMessage id="share" /></span></h2>
                            </div>
                            <div className="cols col-large-10">
                                <div className="white-separator">
                                    <div className="cols-container">
                                        <ul className="videndum-menu">
                                            <li>
                                                <h3>Audio capture</h3>
                                                <ul>
                                                    <li><a target="_blank" rel="noopener" href="https://audixusa.com/">Audix</a></li>
                                                    <li><a target="_blank" rel="noopener" href="https://joby.com/">JOBY</a></li>
                                                    <li><a target="_blank" rel="noopener" href="https://rycote.com/">Rycote</a></li>
                                                </ul>
                                            </li>
                                            <li>
                                                <h3>Backgrounds</h3>
                                                <ul>
                                                    <li><a target="_blank" rel="noopener" href="https://www.manfrotto.com/uk-en/colorama/">Colorama</a></li>
                                                    <li><a target="_blank" rel="noopener" href="https://savageuniversal.com/">Savage</a></li>
                                                    <li><a target="_blank" rel="noopener" href="https://superspec.com/">Superior</a></li>
                                                </ul>
                                            </li>
                                            <li>
                                                <h3>Camera accessories</h3>
                                                <ul>
                                                    <li><a target="_blank" rel="noopener" href="https://teradek.com/">Teradek</a></li>
                                                    <li><a target="_blank" rel="noopener" href="https://woodencamera.com/">Wooden Camera</a></li>
                                                </ul>
                                            </li>
                                            <li>
                                                <h3>Carrying Solutions</h3>
                                                <ul>
                                                    <li><a target="_blank" rel="noopener" href="https://www.gitzo.com/uk-en/">Gitzo</a></li>
                                                    <li><a target="_blank" rel="noopener" href="https://www.lowepro.com/uk-en/">Lowepro</a></li>
                                                    <li><a target="_blank" rel="noopener" href="https://www.manfrotto.com/uk-en/">Manfrotto</a></li>
                                                    <li><a target="_blank" rel="noopener" href="https://www.manfrotto.com/uk-en/national-geographic/">National Geographic*</a></li>
                                                    <li><a target="_blank" rel="noopener" href="https://www.sachtler.com/en/">Sachtler</a></li>
                                                </ul>
                                            </li>
                                            <li>
                                                <h3>Distribution, rental &amp; services</h3>
                                                <ul>
                                                    <li><a target="_blank" rel="noopener" href="http://www.cameracorps.co.uk/">Camera Corps</a></li>
                                                    <li><a target="_blank" rel="noopener" href="https://thecamerastore.co.uk/">The Camera Store</a></li>
                                                </ul>
                                            </li>
                                            <li>
                                                <h3>IP video</h3>
                                                <ul>
                                                    <li><a target="_blank" rel="noopener" href="https://teradek.com/">Teradek</a></li>
                                                </ul>
                                            </li>
                                            <li>
                                                <h3>Lens control systems</h3>
                                                <ul>
                                                    <li><a target="_blank" rel="noopener" href="https://teradek.com/">Teradek</a></li>
                                                </ul>
                                            </li>
                                            <li>
                                                <h3>Lighting & lighting controls</h3>
                                                <ul>
                                                    <li><a target="_blank" rel="noopener" href="https://joby.com/">JOBY</a></li>
                                                    <li><a target="_blank" rel="noopener" href="https://www.litepanels.com/en/">Litepanels</a></li>
                                                    <li><a target="_blank" rel="noopener" href="https://www.manfrotto.com/uk-en/">Manfrotto</a></li>
                                                    <li><a target="_blank" rel="noopener" href="https://www.quasarscience.com/">Quasar Science</a></li>
                                                </ul>
                                            </li>
                                            <li>
                                                <h3>Live streaming</h3>
                                                <ul>
                                                    <li><a target="_blank" rel="noopener" href="https://www.golightstream.com/">Lightstream</a></li>
                                                    <li><a target="_blank" rel="noopener" href="https://teradek.com/">Teradek</a></li>
                                                </ul>
                                            </li>

                                            <li>
                                                <h3>Mobile power</h3>
                                                <ul>
                                                    <li><a target="_blank" rel="noopener" href="https://www.antonbauer.com/en/">Anton/Bauer</a></li>
                                                </ul>
                                            </li>
                                            <li>
                                                <h3>Monitors</h3>
                                                <ul>
                                                    <li><a target="_blank" rel="noopener" href="https://smallhd.com/">SmallHD</a></li>
                                                </ul>
                                            </li>

                                            <li>
                                                <h3>Prompters</h3>
                                                <ul>
                                                    <li><a target="_blank" rel="noopener" href="http://www.autocue.com/">Autocue</a></li>
                                                    <li><a target="_blank" rel="noopener" href="https://www.autoscript.tv/">Autoscript</a></li>
                                                </ul>
                                            </li>
                                            <li>
                                                <h3>Robotic camera systems</h3>
                                                <ul>
                                                    <li><a target="_blank" rel="noopener" href="http://www.cameracorps.co.uk/">Camera Corps</a></li>
                                                    <li><a target="_blank" rel="noopener" href="https://www.vinten.com/en">Vinten</a></li>
                                                </ul>
                                            </li>
                                            <li>
                                                <h3>Smartphonography</h3>
                                                <ul>
                                                    <li><a target="_blank" rel="noopener" href="https://joby.com/">JOBY</a></li>
                                                </ul>
                                            </li>
                                            <li>
                                                <h3> Supports & stabilisers</h3>
                                                <ul>
                                                    <li><a target="_blank" rel="noopener" href="https://www.manfrotto.com/uk-en/avenger-grip/">Avenger</a></li>
                                                    <li><a target="_blank" rel="noopener" href="https://www.gitzo.com/uk-en/">Gitzo</a></li>
                                                    <li><a target="_blank" rel="noopener" href="https://joby.com/">JOBY</a></li>
                                                    <li><a target="_blank" rel="noopener" href="https://www.manfrotto.com/uk-en/">Manfrotto</a></li>
                                                    <li><a target="_blank" rel="noopener" href="https://www.manfrotto.com/uk-en/national-geographic/">National Geographic*</a></li>
                                                    <li><a target="_blank" rel="noopener" href="https://www.ocon.com/">OConnor</a></li>
                                                    <li><a target="_blank" rel="noopener" href="https://www.sachtler.com/en/">Sachtler</a></li>
                                                    <li><a target="_blank" rel="noopener" href="https://www.vinten.com/en">Vinten</a></li>
                                                </ul>
                                            </li>
                                            <li>
                                                <h3>Video transmission systems</h3>
                                                <ul>
                                                    {/* <li><a target="_blank" href="http://www.paralinx.net/">Paralinx</a></li> */}
                                                    <li><a target="_blank" rel="noopener" href="https://teradek.com/">Teradek</a></li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <small className="text-muted">*Manufactured under licence</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="videndum">
                    <div className="videndum-logo">
                        <span className="logo-image"></span>
                    </div>
                    <div className='videndum-sites visible-only-desktop'>
                        <span><FormattedMessage id="our_brands" /></span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Brands;