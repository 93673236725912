import { applicationContext } from 'helpers/services';
import moment from 'moment';
import { useContext } from 'react';

let Copyright = () => {
    let { footerClass, callbackUrl } = useContext(applicationContext);

    return (
        <div className="copyright__container">
            <div className="container align-items-center py-5">
                <div className="row">
                    <div className="pl-2 pr-3 col-auto">
                        <div className={`logo ${footerClass}`}>
                            <a href={callbackUrl}><span></span></a>
                        </div>
                    </div>
                    <div className="col-md-7 col-12 align-items-center">

                        <div className="copyright">
                            <div className="copyright__text">
                                <p>© 1996-{moment().year()} Videndum Media Solutions Spa<br />Videndum Media Solutions Spa Via Valsugana, 100 36022
                                    Cassola (VI) Italy <br />Videndum Media Solutions Spa (VAT N° IT02084740246) | Videndum Italia Spa (VAT N° IT02886030168)</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Copyright;