import { useContext, useState } from 'react';
import * as yup from 'yup';
import { FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { authContext } from 'helpers/services';
import { Spinner } from 'components';


let SignUp = () => {
    let { formatMessage } = useIntl();

    let userSchema = yup.object().shape({
        email: yup.string().default('').required(),
        password: yup.string().default('').required(),
        passwordConfirm: yup.string().default('').required(),
        privacy: yup.boolean().oneOf([true], formatMessage({ id: 'err_check_privacy' })).default(false)
    });

    let authService = useContext(authContext);

    let [isSaving, setSaving] = useState(false);


    return (
        <Formik
            enableReinitialize={true}
            validationSchema={userSchema}
            initialValues={userSchema.getDefault()}
            onSubmit={async (value, action) => {
                if (value.password !== value.passwordConfirm) {
                    toast.error(formatMessage({ id: "passwords_must_match" }));
                    return;
                }

                try {
                    setSaving(true);
                    await authService.signUp(value.email, value.password);
                    toast.success(formatMessage({ id: 'info_verification_code_sent' }));
                } catch (err: any) {
                    setSaving(false);
                }
                action.setSubmitting(false);
            }}
        >
            {({ errors }) =>
                <Form className="needs-validation">
                    <div className="accountpanel">
                        <div className="form-container p-5">
                            <div className="container order-2 order-md-1 bg-white p-5">
                                <div className="container">
                                    <span className="brand-logo"></span>
                                    <h4>
                                        <FormattedMessage id="register_account_title" />
                                    </h4>
                                    <hr className="mt-4" />
                                </div>
                                <div className="container pb-4">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="tab-content">
                                                <div className="tab-pane fade show active" role="tabpanel">
                                                    <div className="form-group pb-3">
                                                        <label htmlFor="textinput">
                                                            <FormattedMessage id="email" /> *
                                                        </label>
                                                        <Field type="email" className={`form-control ${errors.email ? 'is-invalid' : ''}`} name="email" />
                                                        <ErrorMessage name="email" component="span">
                                                            {message => <span className="invalid-feedback">{formatMessage({ id: 'field_required' }, { field: formatMessage({ id: 'email' }) })}</span>}
                                                        </ErrorMessage>
                                                    </div>

                                                    <div className="form-group pb-3">
                                                        <label htmlFor="textinput">
                                                            <FormattedMessage id="password" />
                                                        </label>
                                                        <Field type="password" className={`form-control ${errors.password ? 'is-invalid' : ''}`} name="password" />
                                                        <ErrorMessage name="password" component="span">
                                                            {message => <span className="invalid-feedback">{formatMessage({ id: 'field_required' }, { field: formatMessage({ id: 'password' }) })}</span>}
                                                        </ErrorMessage>
                                                    </div>
                                                    <div className="form-group pb-3">
                                                        <label htmlFor="textinput">
                                                            <FormattedMessage id="password_confirm" />
                                                        </label>
                                                        <Field type="password" className={`form-control ${errors.passwordConfirm ? 'is-invalid' : ''}`} name="passwordConfirm" />
                                                        <ErrorMessage name="passwordConfirm" component="span">
                                                            {message => <span className="invalid-feedback">{formatMessage({ id: 'field_required' }, { field: formatMessage({ id: 'password_confirm' }) })}</span>}
                                                        </ErrorMessage>
                                                    </div>

                                                    <div className="form-group pb-3">
                                                        <Field
                                                            type="checkbox"
                                                            className={`${errors.passwordConfirm ? 'is-invalid' : ''}`}
                                                            name="privacy"
                                                            id="privacy"
                                                        />
                                                        &nbsp;
                                                        <label htmlFor="privacy">
                                                            <FormattedMessage
                                                                id="privacy_read"
                                                                values={{
                                                                    link: <a rel='noopener noreferrer' target='_blank' href={formatMessage({ id: 'privacy_url' })}><b>{formatMessage({ id: 'privacy_notice' })}</b></a>
                                                                }}
                                                            />*
                                                        </label>
                                                        <br />
                                                        <ErrorMessage name="privacy" component="span">
                                                            {message => <span className="invalid-feedback">{formatMessage({ id: 'err_check_privacy' })}</span>}
                                                        </ErrorMessage>
                                                    </div>

                                                    {isSaving &&
                                                        <button className="btn btn-block btn-success w-100 disabled" type="button" disabled>
                                                            <Spinner /><FormattedMessage id="saving" />...
                                                        </button>
                                                    }
                                                    {isSaving === false &&
                                                        <button type="submit" className="btn btn-block btn-success w-100">
                                                            <FormattedMessage id="save" />
                                                        </button>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Form>
            }
        </Formik>
    )
}

export default SignUp;