export let XX = {
    login_with_socials: "Login with social account",
    login_with_credentials: "Login with Vitec Identity account",
    email: "eMail",
    password: "Password",
    field_required: "{field} is required field",
    passwords_must_match: "Password and password confirmation does not match",
    forgot_password: "Forgot password",
    login: "Login",
    logout: "Logout",
    not_registered: "Not registered?",
    not_registered_subtitle: "Click on button below to create new account",
    register: "Register",
    firstname: "First name",
    lastname: "Last name",
    person_birthdate: "Birth Date",
    save: 'Save',
    send_code: 'Send code',
    code: 'Code',
    reset_password: 'Reset password',
    reset_password_subtitle: 'Please set the code that we have sent to your email and new password',
    reset_password_needed: "You need to reset your password to proceed",
    newpassword: 'New password',
    new_password_confirm: "Confirm new password",
    capture: 'Capture.',
    share: 'Share.',
    our_brands: "Our brands",
    site_terms: "Site terms",
    privacy_policy: "Privacy Policy",
    cookies_policy: "Cookies Policy",
    privacy_url: "https://www.videndummediasolutions.com/en/privacy-policy/",
    supports: "Supports",
    bags: "Bags",
    camera_accessories: "Camera Accessories",
    audio_capture: "Audio Capture",
    mobile_power: 'Mobile Power',
    video_transmission_systems: "Video transmission systems",
    prompters: "Prompters",
    motion_control: "Motion Control",
    distribution_rental_services: "Distribution, Rental & Services",
    lighting_and_controls: "Lighting and controls",
    robotic_camera_systems: "Robotic camera systems",
    monitors: "Monitor",
    english: "English",
    italian: "Italian",
    privacy_read: "I have read, understood and accepted {link}",
    privacy_notice: "the Privacy notice",
    billing_country: "Country",
    password_confirm: "Confirm Password",
    register_account_title: "Register new account",
    saving: "Saving",
    confirm: "Confirm",
    signup_confirm: 'Confirm sign up',
    signup_confirmed: 'Your account has been verified, click on login button to login.',
    verification_code_not_arrived: "Verification code is not arrived?",
    click_on_button: "Click on button below to re-send verification code.",
    send: "Send",
    continue_shopping: "Continue shopping",

    info_data_saved: 'Your data has been updated',
    info_verification_code_sent: 'Verification code has been sent to your email',
    info_password_changed: "Password has been changed",
    info_account_not_found: "Your account has not be found.",

    err_password_mismatch: "Passwords does not match",
    err_unable_to_update_account: "Unable to update account",
    err_user_not_found: "User not found",
    err_check_privacy: "You need to check the privacy checkbox",
    err_must_set_email: "You need to provide valid email to send verification code.",
    err_unable_to_send_verification_code: "An error occurred while sending the verification code to your email.",
    err_unable_to_change_password: "An error occurred while setting new password."
}