import UserProvider from './UserContext';
import User from './User';

let Index = () => {
    return (
        <UserProvider>
            <User />
        </UserProvider>
    )
}

export default Index;