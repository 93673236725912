import { useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { useIntl } from 'react-intl';
import { Spinner } from 'components';
import { authContext } from 'helpers/services';


let ResetPassword = () => {
    let location = useLocation();

    let { formatMessage } = useIntl();

    let authService = useContext(authContext);

    let forgotSchema = yup.object().shape({
        code: yup.string().required().default(''),
        email: yup.string().required().default(''),
        newPassword: yup.string().required().default(''),
        newPasswordConfirm: yup.string()
            .oneOf([yup.ref('newPassword'), null], formatMessage({ id: "err_password_mismatch" }))
            .required()
            .default('')
    });

    let [isLoading, setLoading] = useState(false);


    return (
        <div className="accountpanel">
            <div className="form-container">
                <div className="container bg-white p-0">
                    <div className="container py-4 px-4 px-md-5">
                        <span className="brand-logo"></span>
                        <div className="row">
                            <div className="col-12">
                                <h4 className="pb-4">{formatMessage({ id: "reset_password" })}</h4>
                                <h6 className="text-muted">{formatMessage({ id: "reset_password_subtitle" })}</h6>
                                <Formik
                                    validationSchema={forgotSchema}
                                    initialValues={{ ...forgotSchema.getDefault(), email: (location.state as any)?.email || '' }}
                                    onSubmit={async (values, action) => {
                                        try {
                                            setLoading(true);
                                            await authService.forgotPasswordSubmit(values.email, values.code, values.newPassword);
                                            toast.success(formatMessage({ id: 'info_password_changed' }));
                                        } catch (err) {
                                            action.setSubmitting(false);
                                            setLoading(false);
                                            toast.success(formatMessage({ id: 'err_unable_to_change_password' }));
                                        }
                                    }}
                                >
                                    {({ errors }) =>
                                        <Form className="needs-validation">
                                            <div className="form-group py-2">
                                                <label htmlFor="email">{formatMessage({ id: "email" })}</label>
                                                <Field
                                                    id="email"
                                                    className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                                                    disabled={isLoading}
                                                    name="email"
                                                    type="email"
                                                />
                                                <ErrorMessage name="email" component="span">
                                                    {message => <span className="invalid-feedback">{formatMessage({ id: 'field_required' }, { field: formatMessage({ id: 'email' }) })}</span>}
                                                </ErrorMessage>
                                            </div>
                                            <div className="form-group py-2">
                                                <label htmlFor="code">{formatMessage({ id: "code" })}</label>
                                                <Field
                                                    id="code"
                                                    className={`form-control ${errors.code ? 'is-invalid' : ''}`}
                                                    disabled={isLoading}
                                                    name="code"
                                                />
                                                <ErrorMessage name="code" component="span">
                                                    {message => <span className="invalid-feedback">{formatMessage({ id: 'field_required' }, { field: formatMessage({ id: 'code' }) })}</span>}
                                                </ErrorMessage>
                                            </div>
                                            <div className="form-group py-2">
                                                <label htmlFor="newpassword">{formatMessage({ id: "newpassword" })}</label>
                                                <Field
                                                    id="newpassword"
                                                    className={`form-control ${errors.newPassword ? 'is-invalid' : ''}`}
                                                    disabled={isLoading}
                                                    name="newPassword"
                                                    type="password"
                                                />
                                                <ErrorMessage name="newPassword" component="span">
                                                    {message => <span className="invalid-feedback">{formatMessage({ id: 'field_required' }, { field: formatMessage({ id: 'newpassword' }) })}</span>}
                                                </ErrorMessage>
                                            </div>
                                            <div className="form-group py-2">
                                                <label htmlFor="newPasswordConfirm">{formatMessage({ id: "new_password_confirm" })}</label>
                                                <Field
                                                    id="newPasswordConfirm"
                                                    className={`form-control ${errors.newPasswordConfirm ? 'is-invalid' : ''}`}
                                                    disabled={isLoading}
                                                    name="newPasswordConfirm"
                                                    type="password"
                                                />
                                                <ErrorMessage name="newPasswordConfirm" component="span">
                                                    {message => <span className="invalid-feedback">{formatMessage({ id: 'field_required' }, { field: formatMessage({ id: 'new_password_confirm' }) })}</span>}
                                                </ErrorMessage>
                                            </div>

                                            {isLoading && <Spinner />}
                                            {isLoading === false &&
                                                <button className="btn btn-success d-block mt-4 mb-4 w-100" type="submit">
                                                    {formatMessage({ id: "reset_password" })}
                                                </button>
                                            }
                                        </Form>
                                    }
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default ResetPassword;