import { useContext } from 'react';
import { authContext } from 'helpers/services';
import { Country } from 'components';
import UserMenu from './UserMenu';


let Topbar = () => {
    let authService = useContext(authContext);

    return (
        <div className="top-bar">
            <div className="container-fluid align-items-center">
                <div className="row">
                    <div className="col align-items-start">
                        <div className="vitec-brands">
                            <div className="vitec-brands__item">
                                <ul className="head-brands">
                                    <li className="logo-man-lg-k"><a rel="noopener noreferrer" href="https://manfrotto.com/" target="_blank" className="logo"><span></span></a></li>
                                    <li className="logo-bjb-lg-k"><a rel="noopener noreferrer" href="https://joby.com/" target="_blank" className="logo"><span></span></a></li>
                                    <li className="logo-blw-lg-k"><a rel="noopener noreferrer" href="https://www.lowepro.com/" target="_blank" className="logo"><span></span></a></li>
                                    <li className="logo-bgi-lg-k"><a rel="noopener noreferrer" href="https://www.gitzo.com/" target="_blank" className="logo"><span></span></a></li>
                                    <li className="logo-bsy-lg-k"><a rel="noopener noreferrer" href="https://syrplab.com/global/" target="_blank" className="logo"><span></span></a></li>
                                    <li className="logo-bry-lg-k"><a rel="noopener noreferrer" href="https://rycote.com/" target="_blank" className="logo"><span></span></a></li>
                                    <li className="logo-bav-lg-k"><a rel="noopener noreferrer" href="https://www.manfrotto.com/global/avenger-grip/" target="_blank" className="logo"><span></span></a></li>
                                    <li className="logo-bcl-lg-k"><a rel="noopener noreferrer" href="https://www.manfrotto.com/global/colorama/" target="_blank" className="logo"><span></span></a></li>
                                    <li className="logo-bng-lg-k"><a rel="noopener noreferrer" href="https://www.manfrotto.com/global/national-geographic/" target="_blank" className="logo"><span></span></a></li>
                                    <li className="logo-sav-lg-k"><a rel="noopener noreferrer" href="https://www.savageuniversal.com/" target="_blank" className="logo"><span></span></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-auto align-items-end">
                        <div className="row no-gutters w-100">
                            <div className="col-sm-auto p-0 d-flex">
                                <Country />
                                {/* {authService.isLoading === false && authService.isAuthenticated === true &&
                                    <button
                                        className="btn btn-link"
                                        onClick={() => authService.signOut()}
                                    >
                                        <img src="/images/user-alt.svg" alt="" width="15px" />
                                    </button>
                                } */}
                                <UserMenu />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Topbar;