import { useContext, useState, useRef } from 'react';
import { applicationContext } from 'helpers/services';
import { useOutsideClick } from "helpers";
import { useIntl } from 'react-intl';

let Country = () => {
    let { formatMessage } = useIntl();

    let appService = useContext(applicationContext);
    let ref = useRef<HTMLInputElement>(null);

    let [open, setOpen] = useState(false);


    useOutsideClick(ref, () => {
        if (open)
            setOpen(false);
    });


    let chooseCountry = (country: string) => (e: any) => {
        appService.chooseCountry(country);
        setOpen(false);
    }

    let handleDropdown = () => {
        setOpen(!open);
    }

    return (
        <div className={`country-selector dropdown ${open ? 'show' : ''}`}>
            <button
                className="btn btn-sm btn-link mt-1 pr-2 dropdown-toggle "
                type="button"
                id="dropdownMenu2"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                data-country={appService.currentCountry}
                onClick={handleDropdown}
            >
                {formatMessage({ id: appService.localizationCountries.getValueByKey(appService.currentCountry) || 'not-found' })}
            </button>
            <div
                ref={ref}
                className={`dropdown-menu dropdown-menu-end ${open ? 'show' : ''}`}
                aria-labelledby="dropdownMenu2"
            >
                {appService.localizationCountries.map(country =>
                    <button
                        key={country.key}
                        className={`dropdown-item ${country.key === appService.currentCountry ? 'active' : ''}`}
                        data-country={country.key}
                        type="button"
                        onClick={chooseCountry(country.key)}
                    >
                        {formatMessage({ id: country.value })}
                    </button>
                )}
            </div>
        </div>
    );
}

export default Country;