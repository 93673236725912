import React from 'react';
import Header from './Header';
import Footer from './Footer';
import { useContext } from 'react';
import { applicationContext } from 'helpers/services';


interface IProps {
    children: React.ReactNode
}

let Layout = ({ children }: IProps) => {
    let appService = useContext(applicationContext);

    return (
        <div className="container-flud">
            <Header />
            {appService.isLoading === false && children}
            <Footer />
        </div>
    )
}

export default Layout;