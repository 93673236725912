import { useContext } from 'react';
import { useIntl } from 'react-intl';
import { authContext } from 'helpers/services';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { useState } from 'react';
import { Spinner } from 'components';


let ForgotPassword = () => {
    let { formatMessage } = useIntl();

    let authService = useContext(authContext);

    let forgotSchema = yup.object({
        email: yup.string().required().label("Email").default(''),
    })

    let [isLoading, setLoading] = useState(false);


    return (
        <div className="accountpanel">
            <div className="form-container">
                <div className="container bg-white p-0">
                    <div className="container py-4 px-4 px-md-5">
                        <span className="brand-logo"></span>
                        <div className="row">
                            <div className="col-12 pb-4">
                                <h4 className="pb-4">{formatMessage({ id: "forgot_password" })}</h4>
                                <Formik
                                    enableReinitialize={true}
                                    initialValues={forgotSchema.getDefault()}
                                    validationSchema={forgotSchema}
                                    onSubmit={async (value, action) => {
                                        try {
                                            setLoading(true);
                                            action.setSubmitting(true);
                                            await authService.forgotPassword(value.email);
                                            toast.success(formatMessage({ id: 'info_verification_code_sent' }));
                                        } catch (err) {
                                            setLoading(false);
                                            action.setSubmitting(false);
                                            toast.error(formatMessage({ id: 'err_unable_to_send_verification_code' }));
                                        }
                                    }}
                                >
                                    {({ errors }) =>
                                        <Form className="needs-validation">
                                            <div className="form-group">
                                                <label htmlFor="email">{formatMessage({ id: "email" })}</label>

                                                <Field
                                                    id="email"
                                                    type="email"
                                                    name="email"
                                                    className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                                                />

                                                <ErrorMessage name="email" component="span">
                                                    {message => <span className="invalid-feedback">{formatMessage({ id: 'field_required' }, { field: formatMessage({ id: 'email' }) })}</span>}
                                                </ErrorMessage>
                                            </div>

                                            {isLoading && <Spinner />}
                                            {isLoading === false &&
                                                <button type="submit" className="btn btn-success w-100 mt-4 mb-4">
                                                    {formatMessage({ id: "send_code" })}
                                                </button>
                                            }
                                        </Form>
                                    }
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default ForgotPassword;