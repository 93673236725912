import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

let SiteTerms = () => {
    let { formatMessage } = useIntl();

    return (
        <div className="footer-menu__container py-5">
            <div className="container">
                <div className="row">
                    <div className="col-md-6 col-12">
                        <div>
                            <h4 className="fw-bold text-uppercase">
                                <FormattedMessage id="site_terms" />
                            </h4>
                            <ul className="list-group">
                                <li>
                                    <a href={formatMessage({ id: 'privacy_url' })} target="_blank" rel="noreferrer">
                                        <FormattedMessage id="privacy_policy" />
                                    </a>
                                </li>
                                <li>
                                    <Link to="/site-terms/cookies-policy">
                                        <FormattedMessage id="cookies_policy" />
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-6 col-12">
                        <div>
                            <h4 className="fw-bold">VIDENDUM MEDIA SOLUTIONS</h4>
                            <ul className="footer-brands">
                                <li className="logo-man-lg-w"><a href="https://www.manfrotto.com/" target="_blank" rel="noreferrer" className="logo"><span></span></a></li>
                                <li className="logo-bjb-lg-w"><a href="https://joby.com/" target="_blank" rel="noreferrer" className="logo"><span></span></a></li>
                                <li className="logo-blw-lg-w"><a href="https://www.lowepro.com/" target="_blank" rel="noreferrer" className="logo"><span></span></a></li>
                                <li className="logo-bgi-lg-w"><a href="https://www.gitzo.com/" target="_blank" rel="noreferrer" className="logo"><span></span></a></li>
                                <li className="logo-bsy-lg-w"><a href="https://syrplab.com/global/" target="_blank" rel="noreferrer" className="logo"><span></span></a></li>
                                <li className="logo-bry-lg-w"><a href="https://rycote.com/" target="_blank" rel="noreferrer" className="logo"><span></span></a></li>
                                <li className="logo-bav-lg-w"><a href="https://www.manfrotto.com/global/avenger-grip/" target="_blank" rel="noreferrer" className="logo"><span></span></a></li>
                                <li className="logo-bcl-lg-w"><a href="https://www.manfrotto.com/global/colorama/" target="_blank" rel="noreferrer" className="logo"><span></span></a></li>
                                <li className="logo-bng-lg-w"><a href="https://www.manfrotto.com/global/national-geographic/" target="_blank" rel="noreferrer" className="logo"><span></span></a></li>
                                <li className="logo-sav-lg-w"><a href="https://savageuniversal.com/" target="_blank" rel="noreferrer" className="logo"><span></span></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SiteTerms;