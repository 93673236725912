
import Brands from './Brands';
import Copyright from './Copyright';
import SiteTerms from './SiteTerms';

let Footer = () => {
    return (
        <footer>
            <SiteTerms />
            <Copyright />
            <Brands />
        </footer>
    )
}

export default Footer;