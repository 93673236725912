import { useEffect, useState } from 'react';
import Topbar from './Topbar';

let Header = () => {
    let [className, setClassName] = useState('main-header');

    useEffect(() => {
        let onScroll = (e: any) => {
            if (e.target.documentElement.scrollTop && e.target.documentElement.scrollTop > 300)
                setClassName('main-header main-sticky');
            else
                setClassName('main-header');
        };

        window.addEventListener("scroll", onScroll);

        return () => window.removeEventListener("scroll", onScroll);
    }, []);


    return (
        <header className={className}>
            <Topbar />
        </header>
    );
}

export default Header;